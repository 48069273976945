/**
 * TODO: Move all Event-Details-related logic to this controller from EventListController.
 * EventListController is still the parent of this controller, so its functions will be available here if needed.
 */
angular.module('directory').controller('EventDetailsController', function (
  $rootScope, $scope, $filter, $log, $modal, CallService, DateService, Events, EventsService, Messages, Notifier,
  StringService, VidyoMessages, moment
) {
  const vm = this;

  vm.viewState = {
    hasGuestParticipants: false,
    showParticipants: false,
    showStopCall: false,
    showRedial: false,
    isEditingEventTime: false,
    isEventDatePickerOpen: false,
    isEditingNumPatients: false
  };

  vm.eventTimeModel = {};
  vm.numPatientsForm = {};

  const initViewState = () => {
    vm.viewState = {
      ...vm.viewState,
      isEditingEventTime: false,
      isEventDatePickerOpen: false,
      isEditingNumPatients: false
    };
  };

  // VVR Data is loaded asynchronously, hence this logic is called when it updates
  const initViewStateFromVvr = () => {
    const event = $scope.selectedEvent;
    if (!event) {
      return;
    }

    const vvrData = (event.vvr) ? event.vvr.vvrData : null;
    const isVvrInProgress = CallService.isVvrInProgress({
      vvrData
    });

    vm.viewState = {
      ...vm.viewState,
      showStopCall: false, //isVvrInProgress,
      showRedial: isVvrInProgress
    };
  };

  $scope.$watch('selectedEvent.vvr.vvrData', () => {
    initViewStateFromVvr();
  });

  /**
   * If connectTime is in the future (diff between server and client time) return current date/time. This prevents
   * the UI from saying a participant "joined in the future".
   */
  const normalizeParticipants = (participants) => {
    const now = moment();
    (participants || []).forEach((participant) => {
      if (!participant.connectTime) {
        return;
      }
      const connectTime = moment.utc(participant.connectTime).local();
      if (now.isBefore(connectTime)) {
        participant.connectTime = now.toISOString();
      }
    });
  };

  $scope.$on('event_update_participants', (angularEvent, data) => {
    $log.debug('EventDetails:event_update_participants: ', data);
    if ($scope.selectedEvent && data.requestId === $scope.selectedEvent.requestId) {
      normalizeParticipants(data.videoProviderParticipants);
      $scope.selectedEvent.videoProviderParticipants = data.videoProviderParticipants;
    }
  });

  $scope.$on('event_list_update_participants', (angularEvent, participantUpdates) => {
    $log.debug('EventDetails:event_list_update_participants: ', participantUpdates);
    if (!$scope.selectedEvent) {
      return;
    }

    participantUpdates.forEach((participantUpdate) => {
      if ($scope.selectedEvent.requestId === participantUpdate.requestId) {
        vm.viewState.showParticipants = (participantUpdate.hosts > 0 || participantUpdate.guests > 0);
      }
    });
  });

  /**
   * Connect time comes as UTC without being marked with a time zone. We convert it to a valid Date object here.
   *
   * @param connectTime Date/time in the format "2020-02-14T16:13:18.375578" (assumed to be UTC)
   */
  vm.formatParticipantConnectTime = (connectTime) => (
    connectTime ? moment.utc(connectTime).local().format('h:mm A') : undefined
  );

  vm.isClinical = () => (
    $scope.selectedEvent && $scope.isClinicalDetail
  );

  vm.getEventContactLabel = () => {
    if (!$scope.selectedEvent) {
      return '';
    } else if (vm.isClinical()) {
      return 'Consultant';
    } else if ($scope.selectedEvent.category === 'LEARNING') {
      return 'Speaker';
    }
    return 'Chair';
  };

  vm.getSortedParticipants = () => {
    const sortedParticipants = $scope.selectedEvent ? $scope.selectedEvent.participants : [];
    sortedParticipants.sort((participant1, participant2) => {
      if (participant1.type !== participant2.type) {
        return participant2.type.localeCompare(participant1.type);
      } else if (participant1.type === participant2.type &&
        participant1.isHost !== participant2.isHost) {
        return participant1.isHost ? -1 : 1;
      }
      return participant1.name.localeCompare(participant2.name);
    });
    return sortedParticipants;
  };

  vm.participantTypeToLabel = (type) => {
    switch (type) {
      case 'LEGACY':
      case 'PCVC':
        return 'OTN System';
      case 'GUEST':
        return 'Guest via email (OTNinvite)';
      case 'OFFNET':
        return 'Non-OTN system (room-based)';
      default:
        return;
    }
  };

  vm.showRedial = (participant) => (
    vm.viewState.showRedial && !participant.isMySystem && (participant.type === 'LEGACY' || participant.type === 'PCVC')
  );

  vm.editEventTime = () => {
    const {
      selectedEvent
    } = $scope;
    vm.eventTimeModel = {
      startDate: $filter('date')(selectedEvent.startTime, 'yyyy-MM-dd'),
      startTime: $filter('date')(selectedEvent.startTime, 'MM/dd/yyyy HH:mm'),
      endTime: $filter('date')(selectedEvent.endTime, 'MM/dd/yyyy HH:mm')
    };
    vm.viewState.isEditingEventTime = true;
  };

  vm.openEventDatePicker = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    vm.viewState.isEventDatePickerOpen = true;
  };

  /**
   * @param time Either a date object or a string.
   * @returns {Date} A date object representing the given time.
   */
  const timeToDate = (time) => {
    if (typeof time === 'object') {
      return time;
    }
    // Note: Safari cannot parse the time zone "+0000" so we change it to "Z"
    return new Date(time.replace('+0000', 'Z'));
  };

  const saveNewEventTime = (event, newStartTime, newEndTime, onSuccess, onFailure) => {
    const changes = {
      startTime: newStartTime,
      endTime: newEndTime
    };

    $scope.updateEvent(event, changes)
      .then((response) => {
        $log.debug('Event time updated: ', response);
        if (onSuccess) {
          onSuccess(response);
        }
      })
      .catch((rejection) => {
        $log.error('Unable to update time for the event', rejection);
        if (onFailure) {
          onFailure(rejection);
        }
      });
  };

  const showConfirmUpdateEventTimeModal = (event, newStartTime, newEndTime, onSuccess, onFailure, onCancel) => {
    $modal.open({
      templateUrl: 'views/partials/confirmUpdateEventTimeModal.html',
      windowClass: 'modal-small',
      controller: function ($scope, $modalInstance) {
        $scope.event = event;
        $scope.newStartTime = newStartTime;
        $scope.newEndTime = newEndTime;
        $scope.hasNonMemberParticipants = EventsService.hasNonMemberParticipants(event);

        $scope.update = function () {
          saveNewEventTime(event, newStartTime, newEndTime, function (response) {
            $modalInstance.close();
            const message = $scope.hasNonMemberParticipants ?
              Messages.events.reschedule.successGuests.message :
              Messages.events.reschedule.successNoGuests.message;
            Notifier.createMessage('confirmation', message);
            onSuccess(response);
          }, function (rejection) {
            $modalInstance.close();
            onFailure(rejection);
          });
        };

        $scope.cancel = function () {
          onCancel();
          $modalInstance.dismiss('cancel');
        };
      }
    });
  };

  vm.submitEventTime = (selectedEvent, eventTimeModel) => {
    $log.debug('submitEventTime START ID:', selectedEvent.requestId);
    if (!eventTimeModel.startDate) {
      Notifier.createMessage('error', VidyoMessages.otnInvite.msg43.message);
      return;
    }

    // Note: if startDate/startTime are untouched, they will be set to a string not an object, so we reset to the value in selectedEvent
    const eventDateObj = (typeof eventTimeModel.startDate === 'object') ? eventTimeModel.startDate : timeToDate(selectedEvent.startTime);
    const startTime = (typeof eventTimeModel.startTime === 'object') ? eventTimeModel.startTime : timeToDate(selectedEvent.startTime);
    const endTime = (typeof eventTimeModel.endTime === 'object') ? eventTimeModel.endTime : timeToDate(selectedEvent.endTime);

    // Blend date and start/end time form fields into just two start/end date objects
    const startDateTime = DateService.combineDateAndTime(eventDateObj, startTime.getHours(), startTime.getMinutes());
    const endDateTime = DateService.combineDateAndTime(eventDateObj, endTime.getHours(), endTime.getMinutes());

    // Validation
    const todayDate = new Date((new Date()).setHours(0, 0, 0, 0));
    if (eventTimeModel.startDate < todayDate) {
      Notifier.createMessage('error', VidyoMessages.otnInvite.msg43.message);
      return;
    }
    if (startDateTime < new Date().getTime()) {
      Notifier.createMessage('error', VidyoMessages.otnInvite.msg24.message);
      return;
    }
    if (endDateTime.getTime() <= startDateTime.getTime()) {
      Notifier.createMessage('error', Messages.otnInvite.msg25.message);
      return;
    }

    const onUpdateSuccess = (response) => {
      selectedEvent.startTime = response.startTime;
      selectedEvent.endTime = response.endTime;
      vm.cancelEditEventTime();

      // Change to new event date and update the calendar
      // TODO: This may be redundant. See if it can be cleaned up
      $scope.selectDate(selectedEvent.startTime);
      $scope.updateMontlyEvents(selectedEvent.startTime, 'month');
    };

    const onUpdateFailure = (rejection) => {
      if (rejection.data && rejection.data.error === 'E_TIME_CONFLICT') {
        Notifier.createMessage('error', VidyoMessages.otnInvite.msg04.message);
      } else if (rejection.data && rejection.data.error === 'E_EVENT_INPROGRESS') {
        Notifier.createMessage('error', Messages.otnInvite.updateErrorInProgress.message);
      } else {
        Notifier.createMessage('error', Messages.otnInvite.msg19.message, JSON.stringify(rejection));
      }
    };

    // Only show the confirm box when the start date/time has been updated. Used filter to only compare with minute accuracy.
    if ($filter('date')(startDateTime, 'yyyy-MM-dd HH:mm') !== $filter('date')(selectedEvent.startTime, 'yyyy-MM-dd HH:mm')) {
      showConfirmUpdateEventTimeModal(selectedEvent, startDateTime, endDateTime, onUpdateSuccess, onUpdateFailure, $scope.cancelEditEventTime);
    } else {
      saveNewEventTime(selectedEvent, startDateTime, endDateTime, onUpdateSuccess, onUpdateFailure);
    }

    $log.debug('submitEventTime END ', selectedEvent.endTime);
  };

  vm.cancelEditEventTime = () => {
    vm.viewState.isEditingEventTime = false;
  };

  vm.editNumPatients = () => {
    vm.viewState.isEditingNumPatients = true;
    vm.numPatientsForm = {
      numPatients: $scope.selectedEvent.totalNumberOfPatients
    };
  };

  // CS1-3188: All guest participants have the same hardcoded id! Therefore if it's a guest, we need to check by email
  const isSameParticipant = (participantA, participantB) => (
    (participantA.type === 'GUEST' && participantA.type === participantB.type && participantA.email === participantB.email) ||
    (participantA.type !== 'GUEST' && participantA.type === participantB.type && participantA.id === participantB.id)
  );

  vm.submitNumPatientChange = () => {
    const {
      selectedEvent
    } = $scope;
    const {
      numPatients
    } = vm.numPatientsForm;
    $log.debug('submitNumPatientChange - id: ', selectedEvent.requestId);
    vm.viewState.isEditingNumPatients = false;

    // Convention: Only one participant holds the numPatients value. This can be any non-host participant.
    let participantWithNumPatients;
    selectedEvent.participants.forEach((participant) => {
      if (participant.id &&
        !participant.isHost &&
        (!participantWithNumPatients || participant.numOfPatients)) {
        participantWithNumPatients = {
          ...participant,
          numOfPatients: numPatients
        };
      }
    });

    if (!participantWithNumPatients) {
      $log.error('Cannot find a participant to set num patients.');
      return;
    }

    Events.updateParticipant({
        id: selectedEvent.requestId,
        participantId: participantWithNumPatients.id
      },
      participantWithNumPatients,
      (response) => {
        $log.info('Event update num participants ', response);
        // PCVC-1034
        selectedEvent.participants.forEach((participant) => {
          if (isSameParticipant(participant, participantWithNumPatients)) {
            participant.numOfPatients = numPatients;
          }
        });
        selectedEvent.listTitle = $scope.setEventListTitle(selectedEvent);
      },
      (rejection) => {
        $log.error('Unable to save num participants for the event', rejection);
        Notifier.createMessage('error', Messages.otnInvite.msg19.message, JSON.stringify(rejection));
      });
  };

  vm.cancelEditNumPatients = () => {
    vm.viewState.isEditingNumPatients = false;
  };

  const markEventAsEnding = (event) => {
    event.isEnding = true;
    event.listView.participantsText = 'Disconnecting...';
    event.detailsView.missedCallText = '';
    $scope.pollEventListParticipants();
  };

  const onStopCallConfirm = () => {
    const {
      selectedEvent
    } = $scope;
    if (!selectedEvent.vvr || !selectedEvent.vvr.vvrData) {
      return;
    }
    CallService.stopVvrVideoByVvrData(selectedEvent.vvr.vvrData).then((result) => {
      Notifier.createMessage('confirmation', 'Event ended');
      vm.viewState = {
        ...vm.viewState,
        showStopCall: false,
        showRedial: false
      };
      $scope.selectedEvent.vvr.vvrData = {
        ...$scope.selectedEvent.vvr.vvrData,
        ...result
      };
      markEventAsEnding($scope.selectedEvent);
      $scope.$apply();
    }).catch((err) => {
      Notifier.createMessage('error', 'Event could not be ended', '', JSON.stringify(err));
    });
  };

  const showStopCallConfirmModal = () => {
    $modal.open({
      templateUrl: 'views/partials/confirmStopCallModal.html',
      windowClass: 'modal-small',
      controller: function ($scope, $modalInstance) {
        $scope.ok = function () {
          onStopCallConfirm();
          $modalInstance.close();
        };

        $scope.cancel = function () {
          $modalInstance.close();
        };
      }
    });
  };

  vm.stopCall = () => {
    showStopCallConfirmModal();
  };

  vm.redialParticipant = (participant) => {
    const {
      vvr
    } = $scope.selectedEvent;
    if (!vvr || !vvr.vvrData) {
      return null;
    }
    const {
      vvrData
    } = vvr;

    CallService.redialParticipant(vvrData, participant.id);
  };

  $scope.$watch('selectedEvent', () => {
    initViewState();
  });

});
